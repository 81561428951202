define('ember-get-helper/helpers/get-glimmer', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Helper = _ember['default'].Helper || _ember['default'].Object;
  var get = _ember['default'].get;
  var observer = _ember['default'].observer;
  var defineProperty = _ember['default'].defineProperty;
  var setProperties = _ember['default'].setProperties;
  var computed = _ember['default'].computed;
  var oneWay = computed.oneWay;

  exports['default'] = Helper.extend({
    init: function init() {
      _ember['default'].deprecate('ember-get-helper has been included in Ember 2.0. Use of this package is deprecated.');
    },

    compute: function compute(params /*, hash*/) {
      setProperties(this, {
        obj: params[0],
        path: params[1]
      });

      return get(this, 'content');
    },

    obj: null,

    path: null,

    content: null,

    pathDidChange: observer('path', function () {
      var path = get(this, 'path');
      if (path) {
        defineProperty(this, 'content', oneWay('obj.' + path));
      } else {
        defineProperty(this, 'content', null);
      }
    }),

    contentDidChange: observer('content', function () {
      this.recompute();
    })

  });
});