define("ember-cli-focus-input/components/focus-input", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    var FocusInput = _ember["default"].TextField.extend({
        attributeBindings: ["select"],
        didInsertElement: function didInsertElement() {
            this.$().focus();
            var select = this.get("select");
            if (select !== "false") {
                this.$().select();
            }
        }
    });

    exports["default"] = FocusInput;
});